(function () {
    'use strict';

    angular.module('common')
        .component('aflButton', {
            controller: aflButtonController,
            templateUrl: '/static/javascript/directives/afl-button/afl-button.html',
            bindings: {
                icon: "@",
                disabled: '=',
                label: "@",
                buttonText: "=",
                placement: '=',
                innerClass: '@'
            }
        });

    aflButtonController.$inject = [];

    function aflButtonController() {

    }

})();
